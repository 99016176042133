import React, { useState } from 'react';
import './Literary.css';

// Set workerSrc to point to the local pdf.worker.min.js file


function Literary() {
  const [showPoem, setShowPoem] = useState(false);
  const [showPDF, setShowPDF] = useState(false);


  const togglePoem = () => {
    setShowPoem(!showPoem);
  };

  const togglePDF = () => {
    setShowPDF(!showPDF);
  };

  return (
    <div className="literary">
      <h3>Writing</h3>
      <div onClick={togglePoem} className="poem-title">
        A Poem Of My Appreciation (2020)
      </div>
      {showPoem && (
        <div className="poem-content">
          A Poem Of My Appreciation <br />
          <br />
          You sit there <br />
          with your elegant curves. <br />
          You’re a fixated assemblage <br />
          A maintenance of innocence <br />
          <br />
          When I first met you, <br />
          You looked very small <br />
          Or maybe it was compared to my large, solid haul <br />
          But then I saw how you handled my beef stew <br />
          Unlike all the others, I grew fond of you <br />
          <br />
          I visit you now and then <br />
          You greet men, women, and children <br />
          You take many forms <br />
          <br />
          It’s our privacy away from social norms <br />
          You remind me of a therapist who quit <br />
          forced to sit there and take peoples’ shit <br />
          <br />
          You give me a break to think <br />
          To search for the missing link <br />
          You let Einstein, Feynman, and Hawking <br />
          Draw conclusions those which are shocking <br />
          <br />
          Though I take you for granted <br />
          Though I take shits languid <br />
          Between me and you, there is always respect <br />
          you always let me reflect <br />
          From a society, meant to distract <br />
          I come running to you with no tact <br />
          You’re always waiting without question of fact <br />
          <br />
          Sometimes there’s a fleeting second of worry <br />
          But then you make it disappear into the infinite flurry <br />
          You suck out every dirty excretion <br />
          Into your whirl of permeation <br />
          <br />
          You are just a simple mechanism <br />
          My venture with you is a sort of escapism <br />
          Sometimes someone else <br />
          will take your place <br />
          And I’ll have to wait <br />
          Still, you’re a clean slate <br />
          But my patience is dead <br />
          I get sweaty and red <br />
          Hoping to reach you first <br />
          Before my rectal pressure will burst <br />
          <br />
          You take all the revolting waste from me, <br />
          If it be me, sick, on my knee, vomiting into thee <br />
          Or it be the diarrhea or my certain shades of pee <br />
          <br />
          You are a noble place <br />
          You don’t distinguish between race <br />
          You have grace. <br />
          <br />
          Yet, I’m sad to say <br />
          That I have to give you away <br />
          Yes, I’m dumping you <br />
          And yes, you aren’t fancy like a bidet <br />
          And you never disappointed me but for that one time in May. <br />
          I will cherish you <br />
          <br />
          Love, <br />
          M.O.
        </div>
      )}
     <div onClick={togglePDF} className="pdf-link">
        Commentaire Compose a Mallarme (2024)
      </div>
      {showPDF && (
        <div className="pdf-viewer">
          <iframe
            src="/commentaire.pdf"
            width="800px"
            height="600px"
            title="PDF Viewer"
          />
        </div>
      )}
    </div>
  );
}

export default Literary;
