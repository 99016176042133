import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import './App.css';
import Home from './Home';
import Projects from './Projects';
import Art from './Art';
import Literary from './Literary';


function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <div className="header-left">
            <div className="profile-image">
              <img src="profile.jpg" alt="Profile" />
            </div>
            <div className="name">
              <h1><font color="FFA500">M</font>
              <font color="FFA500">i</font>
              <font color="FFA500">c</font>
              <font color="FFA500">h</font>
              <font color="FFA500">a</font>
              <font color="FFA500">e</font>
              <font color="FFA500">l </font>
             Ofengenden</h1>
            </div>
          </div>
          <nav className="header-right">
            <Link to="/">Home</Link>
            <Link to="/projects">Projects</Link>
            <Link to="/art">Art</Link>
            <Link to="/literary">Literary</Link>
          </nav>
        </header>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/art" element={<Art />} />
          <Route path="/literary" element={<Literary />} />
        </Routes>
        
        <footer className="App-footer">
          <a href="https://www.linkedin.com/in/michael-ofengenden/" target="_blank" rel="noopener noreferrer">LinkedIn</a>
          <a href="https://www.github.com/michaelofengend" target="_blank" rel="noopener noreferrer">GitHub</a>
          <a href="https://www.instagram.com/michaelofengenden/" target="_blank" rel="noopener noreferrer">Instagram</a>
        </footer>
      </div>
    </Router>
  );
}

export default App;
