import React from 'react';
import './Art.css';

function Art() {
  return (
    <div className="art-container">
      <div className="columns">
        <div className="column">
          <h3>Photos and Film</h3>
          <div className="image-grid">
            <img src="./2020pics/2020pics1.jpg" alt="2020" />
            <img src="./2020pics/2020pics3.jpg" alt="2020" />
            <img src="./2020pics/2020pics4.jpg" alt="2020" />
            <img src="./2020pics/2020pics5.jpg" alt="2020" />
            <img src="./2020pics/2020pics7.jpg" alt="2020" />
            <img src="./2020pics/2020pics8.jpg" alt="2020" />
            <img src="./2020pics/2020pics9.jpg" alt="2020" />
            <img src="./2020pics/2020pics10.jpg" alt="2020" />
            <img src="./2020pics/caveImage.jpg" alt="2020" />
            <img src="./VeniceMan.jpeg" alt="2020" />
            <img src="./2023summer3.jpg" alt="2020" />
            <img src="./2023summer5.jpg" alt="2020" />

            <img src="./2023summer1.jpg" alt="2020" />
            <img src="./2023summer4.jpg" alt="2020" />
            <img src="./dance.png" alt="2020" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Art;
