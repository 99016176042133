import React, { useState } from 'react';
import './Home.css'; // Create this CSS file for styling

function Home() {
  const [showResume, setShowResume] = useState(false);

  const toggleResume = () => {
    setShowResume(!showResume);
  };

  return (
    <div className="container">
      <p>
        Hey! I'm Michael, currently an Applied Math and Computer 
        Science student (with a minor in French) at 
        <a href="https://www.berkeley.edu/"> UC Berkeley</a>. 
        This year, I'll be studying math through an exchange program with the University of Bordeaux, 
        France. After returning to Berkeley, I expect to graduate in the fall of 2025.
        Please contact me through <a href="mailto:mofengenden@berkeley.edu" > Email</a> regarding any SWE or ML/AI research positions.
      </p>
          <img className="img" src="yosemite2.jpeg" alt="front page" />
      <p>
        For a quick technical summary:  &nbsp; 
        <button onClick={toggleResume}>View CV</button>
      </p>

      {showResume && (
        <div className="resume-modal">
          <button onClick={toggleResume} className="close-button">Close</button>
          <iframe src="/ResumeFA24.pdf" title="Resume" width="100%" height="100%"></iframe>
        </div>
      )}
    </div>
  );
}


export default Home;
