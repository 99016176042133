import React from 'react';
import './Projects.css';

function Projects() {
  return (
    <div  className="container" >
      <h2>Projects</h2>
      <a href="https://askoski.berkeley.edu/" target="_blank" rel="noopener noreferrer">Aula AI 2024</a>
      <p> An app for students to find and optimize schedules and academic career path using LLMs and aggregated data.</p><br />
      <a href="https://www.github.com/michaelofengend" target="_blank" rel="noopener noreferrer">PullApp 2024</a>
      <p> A social media app for finding spontaneous actvities hyperlocally.</p> <br />
      <a href="https://github.com/michaelofengend/StoichiometryFitter" target="_blank" rel="noopener noreferrer"> StoichiometryFitter 2022</a>
      <p> A Webapp which analyzes mineral phases and fits solid solutions through elemental compositions given CSVs, images, or raw percentages. </p><br />

      <p> A few UC Berkeley Computer Vision Projects:</p>
      <a href="https://inst.eecs.berkeley.edu/~cs180/fa23/upload/files/proj4B/mofengenden/Project4/" target="_blank" rel="noopener noreferrer"> Image Warping and Mosaicing 2023</a><br />
      <a href="https://inst.eecs.berkeley.edu/~cs180/fa23/upload/files/proj3/mofengenden/Project3/" target="_blank" rel="noopener noreferrer"> Face Morphing 2023</a><br />
      <a href="https://inst.eecs.berkeley.edu/~cs180/fa23/upload/files/proj6/mofengenden/finalproject/" target="_blank" rel="noopener noreferrer"> Neural Algorithms & Image Quilting 2023</a><br />
      <br>
      </br>
      <p> Also check out the website I created for my mom's Artwork! </p>
      <a href="https://tzofitofengenden.com" target="_blank" rel="noopener noreferrer">tzofitofengenden.com</a>
    </div>
  );
}

export default Projects;
